import React, { createContext, useContext, useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { AuthService } from '../../services/auth/AuthService';
import type { AuthUser } from '../../services/auth/types';
import { googleConfig } from '../../config/auth.config';

interface AuthContextType {
  isAuthenticated: boolean;
  user: AuthUser | null;
  signIn: (provider: 'google', userData: Omit<AuthUser, 'provider'>) => void;
  signOut: () => void;
  error: Error | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthUser | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      const storedUser = AuthService.getStoredAuth();
      if (storedUser) {
        setUser(storedUser);
        setIsAuthenticated(true);
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to restore auth session'));
    }
  }, []);

  const signIn = (provider: 'google', userData: Omit<AuthUser, 'provider'>) => {
    setIsAuthenticated(true);
    setUser({ ...userData, provider });
    setError(null);
  };

  const signOut = () => {
    AuthService.clearAuth();
    setIsAuthenticated(false);
    setUser(null);
    setError(null);
  };

  return (
    <GoogleOAuthProvider clientId={googleConfig.clientId}>
      <AuthContext.Provider value={{ isAuthenticated, user, signIn, signOut, error }}>
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
}