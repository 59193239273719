import { GoogleDriveService } from './google/driveService';

export async function saveToCloud(data: any, provider: string, accessToken: string) {
  switch (provider) {
    case 'google':
      const driveService = new GoogleDriveService(accessToken);
      await driveService.saveData(data);
      break;
    default:
      throw new Error('Unsupported storage provider');
  }
}

export async function loadFromCloud(provider: string, accessToken: string) {
  switch (provider) {
    case 'google':
      const driveService = new GoogleDriveService(accessToken);
      return await driveService.loadData();
    default:
      throw new Error('Unsupported storage provider');
  }
}