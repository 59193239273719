import React, { useState } from 'react';
import { usePersonalStore } from '../../store/personalStore';
import { UserCircle, Phone, MapPin, AlertTriangle, FileKey, Shield } from 'lucide-react';
import type { PersonalInfo } from '../../types/personal';

export function PersonalInformation() {
  const { personalInfo, setPersonalInfo, updatePersonalInfo } = usePersonalStore();
  const [isEditing, setIsEditing] = useState(!personalInfo);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    
    const newInfo: PersonalInfo = {
      id: personalInfo?.id || crypto.randomUUID(),
      firstName: formData.get('firstName') as string,
      lastName: formData.get('lastName') as string,
      email: formData.get('email') as string,
      phone: formData.get('phone') as string,
      address: {
        street: formData.get('street') as string,
        city: formData.get('city') as string,
        state: formData.get('state') as string,
        postalCode: formData.get('postalCode') as string,
        country: formData.get('country') as string,
      },
      emergencyContact: {
        name: formData.get('emergencyName') as string,
        relationship: formData.get('emergencyRelationship') as string,
        phone: formData.get('emergencyPhone') as string,
      },
      driverLicense: {
        number: formData.get('licenseNumber') as string,
        expiryDate: formData.get('licenseExpiry') as string,
        state: formData.get('licenseState') as string,
      },
      insurance: {
        provider: formData.get('insuranceProvider') as string,
        policyNumber: formData.get('policyNumber') as string,
        expiryDate: formData.get('insuranceExpiry') as string,
      },
    };

    if (personalInfo) {
      updatePersonalInfo(newInfo);
    } else {
      setPersonalInfo(newInfo);
    }
    setIsEditing(false);
  };

  if (!isEditing && personalInfo) {
    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold flex items-center gap-2">
            <UserCircle className="w-6 h-6" />
            Personal Information
          </h2>
          <button
            onClick={() => setIsEditing(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Edit Information
          </button>
        </div>

        <div className="bg-white rounded-lg shadow p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
                <UserCircle className="w-5 h-5" />
                Basic Information
              </h3>
              <div className="space-y-2">
                <p><span className="font-medium">Name:</span> {personalInfo.firstName} {personalInfo.lastName}</p>
                <p><span className="font-medium">Email:</span> {personalInfo.email}</p>
                <p><span className="font-medium">Phone:</span> {personalInfo.phone}</p>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
                <MapPin className="w-5 h-5" />
                Address
              </h3>
              <div className="space-y-2">
                <p>{personalInfo.address.street}</p>
                <p>{personalInfo.address.city}, {personalInfo.address.state} {personalInfo.address.postalCode}</p>
                <p>{personalInfo.address.country}</p>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
                <AlertTriangle className="w-5 h-5" />
                Emergency Contact
              </h3>
              <div className="space-y-2">
                <p><span className="font-medium">Name:</span> {personalInfo.emergencyContact.name}</p>
                <p><span className="font-medium">Relationship:</span> {personalInfo.emergencyContact.relationship}</p>
                <p><span className="font-medium">Phone:</span> {personalInfo.emergencyContact.phone}</p>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
                <FileKey className="w-5 h-5" />
                Driver's License
              </h3>
              <div className="space-y-2">
                <p><span className="font-medium">Number:</span> {personalInfo.driverLicense.number}</p>
                <p><span className="font-medium">State:</span> {personalInfo.driverLicense.state}</p>
                <p><span className="font-medium">Expires:</span> {new Date(personalInfo.driverLicense.expiryDate).toLocaleDateString()}</p>
              </div>
            </div>

            <div className="md:col-span-2">
              <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
                <Shield className="w-5 h-5" />
                Insurance Information
              </h3>
              <div className="space-y-2">
                <p><span className="font-medium">Provider:</span> {personalInfo.insurance.provider}</p>
                <p><span className="font-medium">Policy Number:</span> {personalInfo.insurance.policyNumber}</p>
                <p><span className="font-medium">Expires:</span> {new Date(personalInfo.insurance.expiryDate).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h2 className="text-xl font-bold mb-6 flex items-center gap-2">
        <UserCircle className="w-6 h-6" />
        {personalInfo ? 'Edit Personal Information' : 'Add Personal Information'}
      </h2>

      <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                defaultValue={personalInfo?.firstName}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                defaultValue={personalInfo?.lastName}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                defaultValue={personalInfo?.email}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="tel"
                name="phone"
                defaultValue={personalInfo?.phone}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-medium mb-4">Address</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Street Address</label>
                <input
                  type="text"
                  name="street"
                  defaultValue={personalInfo?.address.street}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">City</label>
                <input
                  type="text"
                  name="city"
                  defaultValue={personalInfo?.address.city}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  name="state"
                  defaultValue={personalInfo?.address.state}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Postal Code</label>
                <input
                  type="text"
                  name="postalCode"
                  defaultValue={personalInfo?.address.postalCode}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Country</label>
                <input
                  type="text"
                  name="country"
                  defaultValue={personalInfo?.address.country}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-medium mb-4">Emergency Contact</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  name="emergencyName"
                  defaultValue={personalInfo?.emergencyContact.name}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Relationship</label>
                <input
                  type="text"
                  name="emergencyRelationship"
                  defaultValue={personalInfo?.emergencyContact.relationship}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  name="emergencyPhone"
                  defaultValue={personalInfo?.emergencyContact.phone}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-medium mb-4">Driver's License</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">License Number</label>
                <input
                  type="text"
                  name="licenseNumber"
                  defaultValue={personalInfo?.driverLicense.number}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  name="licenseState"
                  defaultValue={personalInfo?.driverLicense.state}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Expiry Date</label>
                <input
                  type="date"
                  name="licenseExpiry"
                  defaultValue={personalInfo?.driverLicense.expiryDate}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-medium mb-4">Insurance Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Insurance Provider</label>
                <input
                  type="text"
                  name="insuranceProvider"
                  defaultValue={personalInfo?.insurance.provider}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Policy Number</label>
                <input
                  type="text"
                  name="policyNumber"
                  defaultValue={personalInfo?.insurance.policyNumber}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Expiry Date</label>
                <input
                  type="date"
                  name="insuranceExpiry"
                  defaultValue={personalInfo?.insurance.expiryDate}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-4">
          {personalInfo && (
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
          )}
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            {personalInfo ? 'Save Changes' : 'Save Information'}
          </button>
        </div>
      </form>
    </div>
  );
}