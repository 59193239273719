import { GoogleDriveConfig } from './config';

const APP_FOLDER_NAME = 'OMVS Data';
const DATA_FILE_NAME = 'vehicle_data.json';

export class GoogleDriveService {
  private accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  private async findOrCreateAppFolder(): Promise<string> {
    try {
      // Search for existing folder
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=name='${APP_FOLDER_NAME}' and mimeType='application/vnd.google-apps.folder' and trashed=false`,
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );

      const { files } = await response.json();

      if (files && files.length > 0) {
        return files[0].id;
      }

      // Create new folder if not found
      const metadata = {
        name: APP_FOLDER_NAME,
        mimeType: 'application/vnd.google-apps.folder',
      };

      const createResponse = await fetch('https://www.googleapis.com/drive/v3/files', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(metadata),
      });

      const folder = await createResponse.json();
      return folder.id;
    } catch (error) {
      console.error('Error finding/creating app folder:', error);
      throw error;
    }
  }

  async saveData(data: any): Promise<void> {
    try {
      const folderId = await this.findOrCreateAppFolder();
      const metadata = {
        name: DATA_FILE_NAME,
        parents: [folderId],
      };

      // Search for existing file
      const searchResponse = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=name='${DATA_FILE_NAME}' and '${folderId}' in parents and trashed=false`,
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );

      const { files } = await searchResponse.json();
      const method = files && files.length > 0 ? 'PATCH' : 'POST';
      const fileId = files && files.length > 0 ? files[0].id : null;

      const form = new FormData();
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], { type: 'application/json' })
      );
      form.append(
        'file',
        new Blob([JSON.stringify(data)], { type: 'application/json' })
      );

      const url = fileId
        ? `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart`
        : 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart';

      await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: form,
      });
    } catch (error) {
      console.error('Error saving data to Google Drive:', error);
      throw error;
    }
  }

  async loadData(): Promise<any> {
    try {
      const folderId = await this.findOrCreateAppFolder();
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files?q=name='${DATA_FILE_NAME}' and '${folderId}' in parents and trashed=false`,
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );

      const { files } = await response.json();
      if (!files || files.length === 0) {
        return null;
      }

      const fileId = files[0].id;
      const dataResponse = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`,
        {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        }
      );

      return await dataResponse.json();
    } catch (error) {
      console.error('Error loading data from Google Drive:', error);
      throw error;
    }
  }
}