import React, { useState, useCallback } from 'react';
import { useStore } from '../store/useStore';
import { useDropzone } from 'react-dropzone';
import { Wrench, Upload, Trash2 } from 'lucide-react';
import type { ServiceRecord, Vehicle } from '../types';

export function ServiceRecords() {
  const { vehicles, serviceRecords, addServiceRecord, deleteServiceRecord } = useStore();
  const [formData, setFormData] = useState<Partial<ServiceRecord>>({});
  const [files, setFiles] = useState<File[]>([]);
  const [showForm, setShowForm] = useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf']
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const attachments = files.map(file => ({
      id: crypto.randomUUID(),
      name: file.name,
      url: URL.createObjectURL(file),
      type: file.type
    }));

    addServiceRecord({
      id: crypto.randomUUID(),
      ...formData as ServiceRecord,
      date: new Date(formData.date as unknown as string),
      nextServiceDate: new Date(formData.nextServiceDate as unknown as string),
      attachments,
      reminderSet: true
    });

    setFormData({});
    setFiles([]);
    setShowForm(false);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold flex items-center gap-2">
          <Wrench className="w-6 h-6" />
          Service History
        </h2>
        <button
          onClick={() => setShowForm(!showForm)}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          {showForm ? 'Cancel' : 'Add Service Record'}
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Vehicle</label>
              <select
                value={formData.vehicleId || ''}
                onChange={(e) => setFormData({ ...formData, vehicleId: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              >
                <option value="">Select a vehicle</option>
                {vehicles.map((vehicle: Vehicle) => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.year} {vehicle.make} {vehicle.model}
                  </option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Service Date</label>
              <input
                type="date"
                value={formData.date instanceof Date ? formData.date.toISOString().split('T')[0] : ''}
                onChange={(e) => setFormData({ ...formData, date: new Date(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Next Service Due</label>
              <input
                type="date"
                value={formData.nextServiceDate instanceof Date ? formData.nextServiceDate.toISOString().split('T')[0] : ''}
                onChange={(e) => setFormData({ ...formData, nextServiceDate: new Date(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Current Mileage</label>
              <input
                type="number"
                value={formData.mileage || ''}
                onChange={(e) => setFormData({ ...formData, mileage: parseInt(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Service Type</label>
              <select
                value={formData.serviceType || ''}
                onChange={(e) => setFormData({ ...formData, serviceType: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              >
                <option value="">Select service type</option>
                <option value="Oil Change">Oil Change</option>
                <option value="Tire Rotation">Tire Rotation</option>
                <option value="Brake Service">Brake Service</option>
                <option value="Air Filter">Air Filter</option>
                <option value="Battery">Battery</option>
                <option value="Other">Other</option>
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Cost</label>
              <input
                type="number"
                value={formData.cost || ''}
                onChange={(e) => setFormData({ ...formData, cost: parseFloat(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Service Location</label>
              <input
                type="text"
                value={formData.location || ''}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Shop name or location"
                required
              />
            </div>
            
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                value={formData.description || ''}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Details about the service performed"
                required
              />
            </div>
            
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-2">Receipts & Documents</label>
              <div
                {...getRootProps()}
                className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer
                  ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
              >
                <input {...getInputProps()} />
                <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
                <p className="text-sm text-gray-600">
                  Drop receipts or documents here, or click to select
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  Supported formats: JPEG, PNG, PDF
                </p>
              </div>
              
              {files.length > 0 && (
                <ul className="mt-4 space-y-2">
                  {files.map((file) => (
                    <li key={file.name} className="text-sm text-gray-600">
                      {file.name} ({(file.size / 1024).toFixed(1)} KB)
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save Service Record
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {serviceRecords.map((record) => {
          const vehicle = vehicles.find((v) => v.id === record.vehicleId);
          return (
            <div key={record.id} className="bg-white rounded-lg shadow p-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-semibold">{vehicle?.year} {vehicle?.make} {vehicle?.model}</h3>
                  <p className="text-sm text-gray-600">{record.serviceType}</p>
                </div>
                <button
                  onClick={() => deleteServiceRecord(record.id)}
                  className="text-red-500 hover:text-red-600"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
              <div className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm">
                    <span className="font-medium">Date:</span>{' '}
                    {new Date(record.date).toLocaleDateString()}
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">Mileage:</span>{' '}
                    {record.mileage.toLocaleString()}
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">Cost:</span> ${record.cost.toFixed(2)}
                  </p>
                </div>
                <div>
                  <p className="text-sm">
                    <span className="font-medium">Location:</span> {record.location}
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">Next Service:</span>{' '}
                    {new Date(record.nextServiceDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              {record.attachments.length > 0 && (
                <div className="mt-4">
                  <p className="text-sm font-medium mb-2">Attachments:</p>
                  <div className="flex gap-2">
                    {record.attachments.map((attachment) => (
                      <a
                        key={attachment.id}
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-600 text-sm"
                      >
                        {attachment.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}