import { getDaysUntil } from './date';

export interface ServiceStatus {
  color: string;
  bgColor: string;
  borderColor: string;
}

export function getServiceStatusColors(nextServiceDate: Date | string): ServiceStatus {
  const daysUntil = getDaysUntil(nextServiceDate);

  if (daysUntil > 100) {
    return {
      color: 'text-green-600 dark:text-green-400',
      bgColor: 'bg-green-50 dark:bg-green-900/30',
      borderColor: 'border-green-200 dark:border-green-800'
    };
  } else if (daysUntil > 50) {
    return {
      color: 'text-yellow-600 dark:text-yellow-400',
      bgColor: 'bg-yellow-50 dark:bg-yellow-900/30',
      borderColor: 'border-yellow-200 dark:border-yellow-800'
    };
  } else {
    return {
      color: 'text-red-600 dark:text-red-400',
      bgColor: 'bg-red-50 dark:bg-red-900/30',
      borderColor: 'border-red-200 dark:border-red-800'
    };
  }
}