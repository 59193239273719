import { create } from 'zustand';
import type { Vehicle, ServiceRecord } from '../types';

interface Store {
  vehicles: Vehicle[];
  serviceRecords: ServiceRecord[];
  addVehicle: (vehicle: Vehicle) => void;
  updateVehicle: (id: string, vehicle: Partial<Vehicle>) => void;
  deleteVehicle: (id: string) => void;
  addServiceRecord: (record: ServiceRecord) => void;
  updateServiceRecord: (id: string, record: Partial<ServiceRecord>) => void;
  deleteServiceRecord: (id: string) => void;
}

export const useStore = create<Store>((set) => ({
  vehicles: [],
  serviceRecords: [],
  addVehicle: (vehicle) =>
    set((state) => ({ vehicles: [...state.vehicles, vehicle] })),
  updateVehicle: (id, vehicle) =>
    set((state) => ({
      vehicles: state.vehicles.map((v) =>
        v.id === id ? { ...v, ...vehicle } : v
      ),
    })),
  deleteVehicle: (id) =>
    set((state) => ({
      vehicles: state.vehicles.filter((v) => v.id !== id),
      serviceRecords: state.serviceRecords.filter((r) => r.vehicleId !== id),
    })),
  addServiceRecord: (record) =>
    set((state) => ({ serviceRecords: [...state.serviceRecords, record] })),
  updateServiceRecord: (id, record) =>
    set((state) => ({
      serviceRecords: state.serviceRecords.map((r) =>
        r.id === id ? { ...r, ...record } : r
      ),
    })),
  deleteServiceRecord: (id) =>
    set((state) => ({
      serviceRecords: state.serviceRecords.filter((r) => r.id !== id),
    })),
}));