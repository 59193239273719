import { jwtDecode } from 'jwt-decode';
import type { AuthUser, TokenResponse } from './types';
import { googleConfig } from '../../config/auth.config';

export class AuthService {
  private static readonly STORAGE_KEY = 'omvs_auth';

  static async handleGoogleLogin(response: { credential: string }): Promise<AuthUser> {
    if (!response.credential) {
      throw new Error('No credentials received from Google');
    }

    const decoded: any = jwtDecode(response.credential);
    
    const user: AuthUser = {
      id: decoded.sub,
      name: decoded.name,
      email: decoded.email,
      provider: 'google',
      accessToken: response.credential,
      scopes: googleConfig.scopes
    };

    const expiresAt = new Date().getTime() + (decoded.exp * 1000);
    this.storeAuth(user, expiresAt);
    
    return user;
  }

  static getStoredAuth(): AuthUser | null {
    try {
      const stored = localStorage.getItem(this.STORAGE_KEY);
      if (!stored) return null;

      const { user, expiresAt } = JSON.parse(stored);
      
      if (new Date().getTime() > expiresAt) {
        this.clearAuth();
        return null;
      }

      return user;
    } catch (error) {
      this.clearAuth();
      return null;
    }
  }

  private static storeAuth(user: AuthUser, expiresAt: number): void {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify({ user, expiresAt }));
  }

  static clearAuth(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}