import React, { useEffect } from 'react';
import { Dashboard } from './components/Dashboard';
import { VehicleDetails } from './components/VehicleDetails';
import { ServiceRecords } from './components/ServiceRecords';
import { PersonalInformation } from './components/personal/PersonalInformation';
import { LoginPage } from './components/auth/LoginPage';
import { useAuth } from './components/auth/AuthProvider';
import { LayoutGrid, Car, Wrench, UserCircle, LogOut } from 'lucide-react';
import { useStore } from './store/useStore';
import { loadFromCloud, saveToCloud } from './services/cloudStorage';
import { ThemeToggle } from './components/ui/ThemeToggle';

function App() {
  const { isAuthenticated, user, signOut } = useAuth();
  const [activeTab, setActiveTab] = React.useState('dashboard');
  const { vehicles, serviceRecords } = useStore();

  useEffect(() => {
    if (isAuthenticated && user) {
      loadFromCloud(user.provider, user.accessToken).then((data) => {
        if (data) {
          useStore.setState(data);
        }
      });
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (isAuthenticated && user) {
      saveToCloud({ vehicles, serviceRecords }, user.provider, user.accessToken);
    }
  }, [vehicles, serviceRecords, isAuthenticated, user]);

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  const tabs = [
    { id: 'dashboard', name: 'Dashboard', icon: LayoutGrid },
    { id: 'personal', name: 'Personal Info', icon: UserCircle },
    { id: 'vehicles', name: 'My Vehicles', icon: Car },
    { id: 'services', name: 'Service History', icon: Wrench },
  ];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors">
      <nav className="bg-white dark:bg-gray-800 shadow-sm">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center">
            <div className="flex space-x-8">
              {tabs.map((tab) => {
                const Icon = tab.icon;
                return (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`flex items-center px-3 py-4 text-sm font-medium border-b-2 transition-colors ${
                      activeTab === tab.id
                        ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                        : 'border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300'
                    }`}
                  >
                    <Icon className="w-5 h-5 mr-2" />
                    {tab.name}
                  </button>
                );
              })}
            </div>
            <div className="flex items-center space-x-4">
              <ThemeToggle />
              <button
                onClick={signOut}
                className="flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
              >
                <LogOut className="w-5 h-5 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {activeTab === 'dashboard' && <Dashboard />}
        {activeTab === 'personal' && <PersonalInformation />}
        {activeTab === 'vehicles' && <VehicleDetails />}
        {activeTab === 'services' && <ServiceRecords />}
      </main>
    </div>
  );
}

export default App;