import { create } from 'zustand';
import type { PersonalInfo } from '../types/personal';

interface PersonalStore {
  personalInfo: PersonalInfo | null;
  setPersonalInfo: (info: PersonalInfo) => void;
  updatePersonalInfo: (info: Partial<PersonalInfo>) => void;
  clearPersonalInfo: () => void;
}

export const usePersonalStore = create<PersonalStore>((set) => ({
  personalInfo: null,
  setPersonalInfo: (info) => set({ personalInfo: info }),
  updatePersonalInfo: (info) => 
    set((state) => ({
      personalInfo: state.personalInfo 
        ? { ...state.personalInfo, ...info }
        : null
    })),
  clearPersonalInfo: () => set({ personalInfo: null }),
}));