import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import { GoogleLogin } from '@react-oauth/google';
import { AuthService } from '../../services/auth/AuthService';
import { Car, AlertCircle } from 'lucide-react';
import { ThemeToggle } from '../ui/ThemeToggle';

export function LoginPage() {
  const { signIn } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const handleSuccess = async (credentialResponse: any) => {
    try {
      const user = await AuthService.handleGoogleLogin(credentialResponse);
      signIn('google', user);
    } catch (err) {
      console.error('Login error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign in with Google');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 dark:bg-gray-900 transition-colors">
      <div className="absolute top-4 right-4">
        <ThemeToggle />
      </div>
      
      <div className="flex-1 flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <div className="flex justify-center">
              <div className="bg-blue-500 dark:bg-blue-600 p-3 rounded-full">
                <Car className="w-8 h-8 text-white" />
              </div>
            </div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
              Vehicle Service Manager
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Track and manage your vehicle maintenance records
            </p>
          </div>
          
          {error && (
            <div className="bg-red-50 dark:bg-red-900/30 border border-red-200 dark:border-red-800 rounded-lg p-4">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-400 dark:text-red-500" />
                <p className="ml-3 text-sm text-red-700 dark:text-red-400">{error}</p>
              </div>
            </div>
          )}
          
          <div className="mt-8">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300 dark:border-gray-700" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-50 dark:bg-gray-900 text-gray-500 dark:text-gray-400">
                  Sign in with
                </span>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex justify-center">
                <GoogleLogin
                  onSuccess={handleSuccess}
                  onError={() => setError('Failed to sign in with Google')}
                  useOneTap
                  auto_select
                  theme={document.documentElement.classList.contains('dark') ? 'filled_black' : 'outline'}
                  size="large"
                  type="standard"
                  shape="rectangular"
                  width="280"
                  context="signin"
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              By signing in, you agree to store your data securely in Google Drive
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}