import React from 'react';
import { useStore } from '../store/useStore';
import { Car, Calendar, Wrench, AlertCircle, DollarSign } from 'lucide-react';
import { formatDate, getDaysUntil } from '../utils/date';
import { getServiceStatusColors } from '../utils/serviceStatus';
import { Card, CardHeader, CardTitle, CardContent } from './ui/Card';

export function Dashboard() {
  const { vehicles, serviceRecords } = useStore();

  const upcomingServices = serviceRecords
    .filter((record) => new Date(record.nextServiceDate) > new Date())
    .sort((a, b) => new Date(a.nextServiceDate).getTime() - new Date(b.nextServiceDate).getTime());

  const totalCost = serviceRecords.reduce((sum, record) => sum + record.cost, 0);
  const nextService = upcomingServices[0];

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">My Vehicle Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
        <Card>
          <CardContent>
            <div className="flex items-center gap-2">
              <Car className="w-5 h-5 text-blue-500" />
              <h2 className="font-semibold">My Vehicles</h2>
            </div>
            <p className="text-2xl font-bold mt-2">{vehicles.length}</p>
          </CardContent>
        </Card>
        
        <Card>
          <CardContent>
            <div className="flex items-center gap-2">
              <Wrench className="w-5 h-5 text-green-500" />
              <h2 className="font-semibold">Total Services</h2>
            </div>
            <p className="text-2xl font-bold mt-2">{serviceRecords.length}</p>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <div className="flex items-center gap-2">
              <DollarSign className="w-5 h-5 text-purple-500" />
              <h2 className="font-semibold">Total Spent</h2>
            </div>
            <p className="text-2xl font-bold mt-2">${totalCost.toFixed(2)}</p>
          </CardContent>
        </Card>
      </div>

      {nextService && (
        <div className={`
          border rounded-lg p-6 mb-8
          ${getServiceStatusColors(nextService.nextServiceDate).bgColor}
          ${getServiceStatusColors(nextService.nextServiceDate).borderColor}
        `}>
          <h2 className={`text-lg font-semibold mb-2 flex items-center gap-2 ${getServiceStatusColors(nextService.nextServiceDate).color}`}>
            <AlertCircle className="w-5 h-5" />
            Next Service Due
          </h2>
          <div className="flex items-center justify-between">
            <div>
              <p className="font-medium dark:text-white">
                {vehicles.find((v) => v.id === nextService.vehicleId)?.make}{' '}
                {vehicles.find((v) => v.id === nextService.vehicleId)?.model}
              </p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{nextService.serviceType}</p>
            </div>
            <div className="text-right">
              <p className="font-medium dark:text-white">
                {formatDate(nextService.nextServiceDate)}
              </p>
              <p className={`text-sm ${getServiceStatusColors(nextService.nextServiceDate).color}`}>
                {getDaysUntil(nextService.nextServiceDate)} days left
              </p>
            </div>
          </div>
        </div>
      )}

      <Card>
        <CardHeader>
          <CardTitle>
            <div className="flex items-center gap-2">
              <Calendar className="w-5 h-5" />
              Upcoming Maintenance
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {upcomingServices.length > 0 ? (
            <div className="space-y-4">
              {upcomingServices.map((service) => {
                const vehicle = vehicles.find((v) => v.id === service.vehicleId);
                const statusColors = getServiceStatusColors(service.nextServiceDate);
                return (
                  <div key={service.id} className="flex items-center justify-between border-b pb-4">
                    <div>
                      <p className="font-medium dark:text-white">{vehicle?.make} {vehicle?.model}</p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">{service.serviceType}</p>
                      <p className="text-xs text-gray-500 dark:text-gray-500">At: {service.location}</p>
                    </div>
                    <div className="text-right">
                      <p className="font-medium dark:text-white">
                        {formatDate(service.nextServiceDate)}
                      </p>
                      <p className={`text-sm ${statusColors.color}`}>
                        {getDaysUntil(service.nextServiceDate)} days left
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-8 text-gray-500 dark:text-gray-400 flex flex-col items-center">
              <AlertCircle className="w-8 h-8 mb-2" />
              <p>No upcoming maintenance scheduled</p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}